import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import tailwind from "./tailwind.css?url";
import globals from "./globals.css?url";

import appStylesHref from "./app.css?url";
import type {LinksFunction} from "@remix-run/node";
import {Toaster} from "~/components/ui/sonner";
import {MetaFunction} from "@remix-run/node";

export const links: LinksFunction = () => [
    { rel: "stylesheet", href: tailwind },
    { rel: "stylesheet", href: globals },
    { rel: "stylesheet", href: appStylesHref },
    {
        rel: "icon",
        href: "/favicon.svg",
        type: "image/svg+xml",
    },
];

export const meta: MetaFunction = () => {
    return [
        { title: "Forbi" },
        {
            property: "og:title",
            content: "Forbi",
        },
        {
            name: "description",
            content: "Forbi",
        },
    ];
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
    return (
        <html lang="en">
        <head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <Meta />
            <Links />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
                        rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet"/>
        </head>
        <body className="bg-[#313037] text-white">
        <Outlet />
        <Toaster position="top-right" />
        <ScrollRestoration />
        <Scripts />
        <div dangerouslySetInnerHTML={{__html: "<script type=\"text/javascript\" >\n" +
                "   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
                "   m[i].l=1*new Date();\n" +
                "   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +
                "   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
                "   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +
                "\n" +
                "   ym(98367988, \"init\", {\n" +
                "        clickmap:true,\n" +
                "        trackLinks:true,\n" +
                "        trackHash:true,\n" +
                "        accurateTrackBounce:true,\n" +
                "        webvisor:true\n" +
                "   });\n" +
                "</script>"}} >
        </div>
        </body>
        </html>
    );
}